// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import lgnBackPic from "../../Assets/Login/login-backgraound.webp";
// import axios from "axios";
// import { encryptData } from "../localStorageUtils";
// import Tostify from "../Common/Tostify";
// import { toast } from "react-toastify";
// import { useEffect } from "react";
// import { getOtplessToken, hitOTPlessSdk } from "../../helpers/otpless";

// const Login = () => {
//   let navigate = useNavigate();
//   const [phone, setPhone] = useState("");
//   const [otp, setOtp] = useState(Array(6).fill(""));
//   const [otpStep, setOtpStep] = useState(false);
//   const [isLoading, setIsLoading] = useState();
//   const [errors, setErrors] = useState({});
//   const [country, setCountry] = useState("+91");
//   const [resendTimer, setResendTimer] = useState(0);
//   const [canResend, setCanResend] = useState(false);
//   const updatedPhone = country + phone;
//   const [rememberMe, setRememberMe] = useState(false);

//   useEffect(() => {
//     const toastId = toast.onChange((payload) => {
//       if (payload.status === 'added') {
//         setTimeout(() => {
//           toast.dismiss(payload.id);
//         }, 10000);
//       }
//     });

//     return () => {
//       toast.dismiss(toastId); // Changed from toast.remove to toast.dismiss
//     };
//   }, []);

//   // Handle resend timer
//   useEffect(() => {
//     let interval;
//     if (resendTimer > 0) {
//       interval = setInterval(() => {
//         setResendTimer((prev) => prev - 1);
//       }, 1000);
//     } else if (otpStep) {
//       setCanResend(true);
//     }

//     return () => clearInterval(interval);
//   }, [resendTimer, otpStep]);


//   useEffect(() => {
//     const storedPhone = localStorage.getItem("rememberedPhone");
//     if (storedPhone) setPhone(storedPhone?.slice(3));
//   }, []);

//   const handleResendOTP = async () => {
//     try {
//       setCanResend(false);
//       setOtp(Array(6).fill(""));
//       setResendTimer(180); // 3 minutes

//       const request = { channel: 'PHONE', phone: phone, countryCode: country };
//       const initiateOtp = await hitOTPlessSdk({ requestType: "initiate", request });

//       if (initiateOtp.success) {
//         toast.success("OTP sent successfully!");
//       } else {
//         toast.error(initiateOtp.response.errorMessage || "Failed to send OTP.");
//         setCanResend(true);
//         setResendTimer(0);
//       }
//     } catch (error) {
//       toast.error("Failed to resend OTP.");
//       setCanResend(true);
//       setResendTimer(0);
//     }
//   };

//   const validateInputs = () => {
//     const newErrors = {};
//     if (!phone || phone.length !== 10) newErrors.phone = "Please enter a valid phone number.";
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     if (!validateInputs()) return;

//     if (rememberMe) {
//       localStorage.setItem("rememberedPhone", updatedPhone);
//       // localStorage.setItem("rememberedPassword", password);
//     }


//     if (!otpStep) {
//       // Step 1: Send OTP
//       try {
//         const request = { channel: 'PHONE', phone: phone, countryCode: country };
//         setIsLoading(true);
//         const initiateOtp = await hitOTPlessSdk({ requestType: "initiate", request });

//         if (initiateOtp.success) {
//           setOtpStep(true);
//           setResendTimer(180); // Start 3-minute timer
//           toast.success("OTP sent successfully!");
//         } else {
//           toast.error(initiateOtp.response.errorMessage || "Failed to send OTP.");
//         }
//         setIsLoading(false);
//       } catch (error) {
//         toast.error("Failed to send OTP.");
//         setIsLoading(false);
//       } finally {
//         setIsLoading(false);
//       }
//     } else {
//       // Step 2: Verify OTP
//       const otpValue = otp.join("");
//       const verifyRequest = { channel: 'PHONE', phone: phone, otp: otpValue, countryCode: country };
//       setIsLoading(true);

//       const verifyOtp = await hitOTPlessSdk({ requestType: "verify", request: verifyRequest });

//       if (verifyOtp.success) {

//         try {
//           const token = await getOtplessToken();

//           if (!token) {
//             toast.error("Authentication token not received. Please try again.");
//             setIsLoading(false);
//             return;
//           }

//           const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
//             otplessToken: token,
//             phone: updatedPhone,
//           });
//           let resData = res.data.data;
//           let resUserRole = res.data.data.user.role;

//           encryptData(resData);
//           localStorage.setItem("superAdminrefresh_token", res.data.refreshToken);
//           if (resUserRole === "superAdmin") {
//             navigate("/superadmin/dashboard");
//           } else {
//             tostifyErr("You do not have access to this resource  ");
//             setIsLoading(false);
//           }

//           setIsLoading(false);
//         } catch (error) {
//           toast.error(error?.response?.data?.message || "Login failed.");
//           setIsLoading(false);
//         }
//       } else {
//         toast.error("Invalid OTP.");
//         setIsLoading(false);
//       }
//     }
//   };

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const remainingSeconds = seconds % 60;
//     return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
//   };

//   const tostifyErr = (msg) => {
//     toast.error(msg, {
//       position: "top-center",
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "colored",
//     });
//   };

//   return (
//     <div>
//       <div
//         style={{ backgroundImage: `url(${lgnBackPic})` }}
//         className="bg-cover bg-center h-screen"
//       >
//         <Tostify />

//         <div className="flex justify-center items-center h-full">
//           <div className="w-full md:w-6/12 lg:w-4/12 px-2 py-8 md:p-0">
//             <div className="relative flex flex-col min-w-0 break-words mb-6 shadow-lg rounded-2xl bg-white border-0 p-8">
//               <div className="text-center text-xl text-[#2b2f32] font-bold mb-4">
//                 {otpStep ? "Verify OTP" : "Enter Mobile Number"}
//               </div>

//               <form onSubmit={handleLogin}>
//                 <div className="relative mb-5">
//                   <label className="text-black block text-sm mb-2">
//                     Mobile Number
//                     <span className="text-orange-600 text-lg"> *</span>
//                   </label>
//                   <div className="flex gap-2 items-center">
//                     <select
//                       name="Country code"
//                       value={country}
//                       onChange={(e) => setCountry(e.target.value)}
//                       className={`text-black border px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-orange-500 ${errors.phone ? "ring-orange-600" : ""}`}
//                     >
//                       <option value="+91">+91</option>
//                       <option value="+1">+1</option>
//                       <option value="+44">+44</option>
//                       <option value="+61">+61</option>
//                     </select>

//                     <input
//                       type="text"
//                       value={phone}
//                       onChange={(e) => setPhone(e.target.value)}
//                       className={`flex-grow text-black border px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-orange-500 ${errors.phone ? "ring-orange-600" : ""}`}
//                       placeholder="Enter Your Phone No."
//                     />
//                   </div>

//                   {errors.phone && <p className="text-orange-600 text-sm mt-1">{errors.phone}</p>}
//                 </div>
//                 <div className="flex items-center mb-5 cursor-pointer">
//                   <input
//                     checked={rememberMe}
//                     onChange={() => setRememberMe(!rememberMe)}
//                     type="checkbox"
//                     className="border-black rounded h-5 w-5 mr-2"
//                   />
//                   <span className="text-black text-sm">Remember me</span>
//                 </div>

//                 {otpStep && (
//                   <div className="relative mb-5">
//                     <label className="text-black block text-sm mb-2">
//                       OTP
//                       <span className="text-orange-600 text-lg"> *</span>
//                     </label>
//                     <div className="flex gap-2 items-center">
//                       {otp?.map((digit, idx) => (
//                         <input
//                           key={idx}
//                           type="text"
//                           value={digit}
//                           onChange={(e) => {
//                             const newOtp = [...otp];
//                             newOtp[idx] = e.target.value;

//                             // Update OTP state
//                             setOtp(newOtp);

//                             // Move to next input if current input is filled
//                             if (e.target.value.length === 1 && idx < otp.length - 1) {
//                               document.getElementById(`otp-input-${idx + 1}`).focus(); // Focus next input
//                             }
//                           }}
//                           onKeyDown={(e) => {
//                             if (e.key === 'Backspace' && idx > 0 && otp[idx] === "") {
//                               document.getElementById(`otp-input-${idx - 1}`).focus(); // Focus previous input
//                             }
//                           }}
//                           maxLength="1"
//                           id={`otp-input-${idx}`} // Unique ID for each input field
//                           className="w-10 h-10 text-center text-lg border rounded focus:ring-1 focus:ring-primaryColor focus:outline-none"
//                         />
//                       ))}
//                     </div>
//                     <div className="flex justify-between items-center mt-4">
//                       {resendTimer > 0 ? (
//                         <p className="text-sm text-gray-500">
//                           Resend available in {formatTime(resendTimer)}
//                         </p>
//                       ) : (
//                         canResend && (
//                           <button
//                             type="button"
//                             onClick={handleResendOTP}
//                             className="text-orange-500 hover:text-orange-600 text-sm font-medium"
//                           >
//                             Resend OTP
//                           </button>
//                         )
//                       )}
//                     </div>
//                   </div>

//                 )}

//                 <div className="flex items-center justify-center mt-6">
//                   <button
//                     disabled={isLoading}
//                     type="submit"
//                     className="h-12 w-3/5 flex items-center justify-center text-lg font-semibold text-white bg-orange-500 hover:bg-orange-600 transition ease-in-out duration-300 rounded-xl shadow-lg"
//                   >
//                     {isLoading ? (
//                       <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
//                     ) : otpStep ? (
//                       "Verify OTP"
//                     ) : (
//                       "Send OTP"
//                     )}
//                   </button>
//                 </div>
//               </form>

//               <div className="flex items-center justify-center text-[#718092] mt-4">
//                 <button
//                   onClick={() => navigate("/forgot-password")}
//                   className="text-primaryColor hover:text-primaryHoverColor transition ease-in-out duration-300 font-medium hover:underline"
//                 >
//                   Forgot Password?
//                 </button>
//               </div>
//               <div id="response" className="text-primaryColor hover:text-primaryHoverColor transition ease-in-out duration-300 tracking-wide"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import lgnBackPic from "../../Assets/Login/login-backgraound.webp";
import axios from "axios";
import { encryptData } from "../localStorageUtils";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import Tostify from "../Common/Tostify";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [country, setCountry] = useState("+91");
  const [contryErr, setCountryErr] = useState("");
  const updatedPhone = country + phone;
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    // On component mount, check if there are stored credentials and update the state
    const storedEmail = localStorage.getItem("rememberedemail");
    const storedPhone = localStorage.getItem("rememberedphone");
    const storedPassword = localStorage.getItem("rememberedpassword");

    if (storedEmail) setEmail(storedEmail);
    if (storedPhone) setPhone(storedPhone?.slice(3, 13));
    if (storedPassword) setPassword(storedPassword);
  }, []);

  const rememberMeBtn = () => {
    if (rememberMe) {
      localStorage.setItem("rememberedemail", email);
      localStorage.setItem("rememberedphone", updatedPhone);
      localStorage.setItem("rememberedpassword", password);
    }
  };

  const handleClick = () => {
    setVisible((prev) => !prev);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // if (!email) {
    //   setEmailErr(true);
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    //   setEmailErr(true);
    // }
    if (!phone) {
      setPhoneErr(true);
    } else if (!password) {
      setPasswordErr(true);
    } else {
      let data = JSON.stringify({
        // email: email,
        phone: updatedPhone,
        password: password,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/user/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      if (rememberMe) {
        localStorage.setItem("rememberedemail", email);
        localStorage.setItem("rememberedphone", updatedPhone);
        localStorage.setItem("rememberedpassword", password);
      }

      setIsLoading(true);
      await axios
        .request(config)
        .then((res) => {
          let resData = res.data.data;
          let resUserRole = res.data.data.user.role;

          encryptData(resData);
          localStorage.setItem("superAdminrefresh_token", res.data.refreshToken);
          if (resUserRole === "superAdmin") {
            navigate("/superadmin/dashboard");
          } else {
            tostifyErr("You do not have access to this resource  ");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Login Error", error);
          tostifyErr(error?.response?.data?.message);
        });
    }
  };

  const tostifyErr = (msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };


  //phone--+919770330933
  //password--Chetan@7
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${lgnBackPic})` }}
        className="bg-cover bg-center h-screen"
      >
        <Tostify />

        <div className="container mx-auto h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-5/12">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white border-0 pl-12 pr-12 pt-8 pb-12">
                <div className="btn-wrapper text-center">
                  <div className="text-xl text-[#2b2f32] font-bold ">
                    Welcome Back
                  </div>
                </div>

                <div className="flex-auto mt-2">
                  <form onSubmit={handleLogin}>

                    <div className="relative w-full mb-3">
                      <div className="text-[#132c4a] block text-sm mb-2">
                        Mobile Number
                        <span className="text-red-600 text-lg"> *</span>
                      </div>
                      <div className="flex gap-10 sm:gap-5 md:gap-4">
                        <div className="w-[20%] ">
                          <select
                            name="Country code"
                            id="Conutry code"
                            value={country}
                            onChange={(e) => {
                              setCountryErr(false);
                              setCountry(e.target.value);
                            }}
                            className={
                              contryErr
                                ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-max ease-linear transition-all duration-150  "
                                : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-max ease-linear transition-all duration-150  "
                            }
                          >
                            <option value="+91">+91</option>
                          </select>
                        </div>
                        <div className="w-[78%]">
                          <input
                            type="text"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                              setPhoneErr(false);
                            }}
                            className={
                              phoneErr
                                ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                            }
                            placeholder="Enter Your Phone No."
                          />
                        </div>
                      </div>
                    </div>

                    <div className="relative w-full mb-5">
                      <div className="text-[#132c4a] block text-sm mb-2">
                        Password
                        <span className="text-red-600 text-lg"> *</span>
                      </div>
                      <input
                        value={password}
                        onChange={(e) => {
                          setPasswordErr(false);
                          setPassword(e.target.value);
                        }}
                        type={visible ? "text" : "password"}
                        className={
                          passwordErr
                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  pr-10"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                        }
                        placeholder="Password"
                      />
                      {visible ? (
                        <div className="absolute right-1 top-12 ">
                          <IoMdEye
                            size={20}
                            onClick={handleClick}
                            className="mx-2 text-[#828282]"
                          />
                        </div>
                      ) : (
                        <div className="absolute right-1 top-12">
                          <IoMdEyeOff
                            size={20}
                            onClick={handleClick}
                            className="mx-2 text-[#828282]"
                          />
                        </div>
                      )}
                    </div>

                    <div className="inline-flex items-center cursor-pointer">
                      <input
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        type="checkbox"
                        className="border-[#132c4a] rounded h-5 w-5 mr-2"
                      />

                      <span className="text-[#132c4a] block text-sm">
                        Remember me
                      </span>
                    </div>

                    <div className="w-full flex items-center justify-center mt-5">
                      <button
                        onClick={handleLogin}
                        className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-[#fe8740] hover:bg-[#f95e01] transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                      >
                        {isLoading ? (
                          <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                        ) : (
                          <span>Log In</span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
