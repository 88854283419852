import React, { useState } from "react";
import { useEffect } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import stf from "../../Assets/staff.png";
import {
  fetchBranch,
  fetchManager,
  fetchStaff,
} from "../../FetchDataMethods/data";

const ManagerBranchDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [branch, setBranch] = useState([]);
  const [staff, setStaff] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const filteredBranch = branch.filter((item) => item._id === id);
  const filteredStaff = staff?.filter((item) => item?.organizationId === id);

  useEffect(() => {
    fetchBranchData();
    fetchStaffData(id);
  }, []);

  const fetchBranchData = async () => {
    setIsLoading(true);
    const data = await fetchBranch();
    setBranch(data);
    setIsLoading(false);
  };

  const fetchStaffData = async () => {
    setIsLoading(true);
    const data = await fetchStaff();
    setStaff(data);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className="flex items-center">
        <IoMdArrowRoundBack
          size={25}
          className="mt-3"
          onClick={() => navigate("/superadmin/managers")}
        />
        <div className="font-bold text-2xl mt-4 tracking-wide w-full ml-2">
          Branch Overview
        </div>
      </div>

      {isLoading ? (
        <div className="bg-[#fff] flex gap-5 p-5 mt-4 rounded-md shadow-lg ">
          <div className="flex flex-col justify-between gap-3 text-[#646671] text-lg w-1/3">
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-2 w-1/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
          </div>
          <div className="flex flex-col justify-between gap-3 text-[#646671] text-lg w-1/3">
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
            <div className="animate-pulse h-3 w-2/3 flex items-center justify-center  bg-skeletonLoaderColor rounded mb-3 "></div>
          </div>
        </div>
      ) : (
        <div className="bg-white gap-4 p-4 mt-2 shadow-md rounded-md">
          <div className="branch-details flex flex-col p-5 rounded-md border border-gray-400">
          <div className="text-2xl font-bold">Branch Details</div>
          <div className="flex">
            <div className="flex flex-col text-gray-600  w-1/3">
              <span className="">Name:</span>
              <span>Address:</span>
              <span>Phone:</span>
              <span>State:</span>
              <span>Email:</span>
              <span>City:</span>
              <span>Postal:</span>
            </div>
            <div className="flex flex-col justify-center">
              <span className=" font-bold capitalize">
                {branch[0]?.name}
              </span>
              <span className="capitalize">{filteredBranch[0]?.address}</span>
              <span>{filteredBranch[0]?.primaryPhone}</span>
              <span className="capitalize">{filteredBranch[0]?.state}</span>
              <span>{filteredBranch[0]?.organizationEmail}</span>
              <span className="capitalize">{filteredBranch[0]?.city}</span>
              <span>{filteredBranch[0]?.postalCode}</span>
            </div>
            </div>
          </div>
        </div>
      )}

      <div className="mx-auto mt-6 gap-6 flex flex-wrap justify-evenly">
        {filteredStaff?.map((v, i) => {
          return (
            <div
              className="grid grid-cols-1 bg-white p-5 rounded-md shadow-md w-64 ease-in-out duration-300 hover:scale-[1.05]"
              key={i}
            >
              <div>
                <img src={stf} alt="logo" className="w-24  h-24 mx-auto" />
              </div>
              <div className="flex flex-col gap-1 justify-center text-center mt-2 capitalize">
                <div>
                  <span className="text-lg font-bold">
                    {v?.userId?.firstName + " " + v?.userId?.lastName}
                  </span>
                </div>
                <div>
                  <span className="text-lg text-gray-600">EmpId:</span>
                  <span className="text-sm font-semibold">{v?.employeeId}</span>
                </div>
                <div>
                  <span className="text-lg text-gray-600">Ph:</span>
                  <span className="text-sm font-semibold">
                    {v?.userId?.phone}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default ManagerBranchDetails;
